import React from "react";


function Core() {
    return(
        <div>
            <header>
                <h2>Mario Alberto Briseño Zamarripa</h2>
                <p>Software Engineer</p>
            </header>
        </div>
    )
}

export default Core;
